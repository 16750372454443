import { useState } from "react";
import { Grid, Typography, Button, MenuList, MenuItem, ListItemIcon, ListItemText, IconButton } from "@mui/material";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import { LoginMFA_Title, LoginMFA_Label } from "../constant";
import ApiClient from "../../../../common/API";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import CloseIcon from '@mui/icons-material/Close';
import { splitDefaultDevice } from "../utils";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

const IconStyle = {
    color: "#1976D2",
    fontSize: 40,
};

const methodString = {
    otp_email: "Email OTP",
    otp_totp: "Authenticator App",
}


export default function SelectionList({ setShowModal, setSelectedCurrentView, defaultDevice, userId, hasTOTPDevice, setTotpDevice, userDevices}) {
    const handleClose = () => setShowModal(false);
    const defaultStringDevice = defaultDevice ? splitDefaultDevice(defaultDevice) : 'otp_email'
    const [selectedCurrentMethod, setSelectedCurrentMethod] = useState(defaultStringDevice);
    
    
    
    const handleSetUp = async (method) => {
        try {
            await ApiClient().post(`user/${userId}/get_config_url/`).then(response => {
                setTotpDevice(response?.data?.totp_device_id)
                setSelectedCurrentView(method)
            });
        } catch (error) {   
            const errorMessage = error.response?.data?.message;
            console.error(errorMessage);
        }
    };

    const queryClient = useQueryClient();

    const selectedUserDevice = userDevices.find( device => device.startsWith(selectedCurrentMethod))

    const handleSetAsDefault = async (method) => {
        try {
            
            await ApiClient().post('user/set_default_mfa_option/', {
                device_id: selectedUserDevice
            });

            toast.success(`Default MFA method is set to ${methodString[method]}`);
            queryClient.invalidateQueries('authProfile-logged');

        } catch (error) {
            const errorMessage = error.response?.data?.detail;
            toast.error(errorMessage);
        }
    };

    const forSetup = !hasTOTPDevice && selectedCurrentMethod === 'otp_totp'

    const otpMethods = [
        {
            method: "otp_email",
            label: "Email OTP",
            icon: <EmailOutlinedIcon style={IconStyle} />,
        },
        {
            method: "otp_totp",
            label: "Authenticator App",
            icon: <PhoneAndroidOutlinedIcon style={IconStyle} />,
            hasTotp: !hasTOTPDevice && selectedCurrentMethod !== 'otp_totp'
        },
    ];

    return (
        <Grid container xs={12}>
            <Grid item xs={12} >
                <IconButton aria-label="close" onClick={() => handleClose()} sx={{ position: 'fixed', top: 5, right: 5 }}>
                    <CloseIcon />
                </IconButton>
            </Grid>
            <Grid item xs={12} textAlign="center" my={1} mt={2.5}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>{LoginMFA_Title}</Typography>
            </Grid>
            <Grid item xs={12} textAlign="center" my={1}>
                <Typography>{LoginMFA_Label}</Typography>
            </Grid>

            <Grid item xs={12} my={1}>
                <MenuList>
                    {otpMethods.map(({ method, label, icon, hasTotp }) => (
                        <MenuItem
                            key={method}
                            selected={selectedCurrentMethod === method}
                            onClick={() => setSelectedCurrentMethod(method)}
                            onKeyDown={(e) => e.preventDefault()}
                            sx={{
                                opacity: hasTotp &&  0.5,   
                            }}
                        >
                            <ListItemIcon>{icon}</ListItemIcon>
                            <ListItemText>{label}</ListItemText>
                            {defaultStringDevice === method && (
                                <ListItemIcon>
                                    <CheckRoundedIcon sx={{ color: "#1976D2" }} />
                                </ListItemIcon>
                            )}
                        </MenuItem>
                    ))}
                </MenuList>
            </Grid>

            {forSetup ? (
                <Grid display={'flex'} xs={12}>
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ textTransform: "none" }}
                        onClick={() => handleSetUp(selectedCurrentMethod)}
                    >
                        Set Up
                    </Button>
                </Grid>
            ) : (
                <Grid xs={12}>
                    <Button disabled={defaultStringDevice === selectedCurrentMethod} fullWidth variant="outlined" sx={{ textTransform: "none" }}
                        onClick={() => handleSetAsDefault(selectedCurrentMethod)}
                    >
                        Set as default
                    </Button>
                </Grid>
            )}
        </Grid>
    );
}
