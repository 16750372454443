import { useState, useContext } from "react";
import { ListItemIcon, MenuItem, Modal, Card } from "@mui/material";
import { WindowSizeContext } from "../../../../common/context/WindowSizeContext";
import MFAFormModal from "./MFAFormModal";
import { useQuery } from "react-query";
import ApiClient from "../../../../common/API";


export default function MFAModalForm({ item }) {
    const { isOnMobile } = useContext(WindowSizeContext);
    const [showModal, setShowModal] = useState(false);

     // Fetching user profile data
    const { data: userDetails, isLoading } = useQuery('authProfile-logged', () => 
        ApiClient().get('/auth/my_profile').then(res => res.data)
    );
  

    const onHandleModalClick = () => {
        if(userDetails && !isLoading) setShowModal(prevState => !prevState);
    };

    const modalStyle = {
        width: isOnMobile ? '80vw' : '20vw',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem',
    };

    return (
        <>
            {
                <Modal 
                    open={showModal} 
                    onClose={()=> onHandleModalClick()} 
                    disableAutoFocus
                    disableEnforceFocus
                >
                    <Card sx={modalStyle}>
                        <MFAFormModal setShowModal={setShowModal} userDetails={userDetails} isLoading={isLoading}/> 
                    </Card>
                </Modal> 
            }

            <MenuItem
                key={item.name}
                onClick={onHandleModalClick}
                onKeyDown={e => e.stopPropagation()}
                sx={{
                    p: 1,
                    ml: 1,
                    mr: 1,
                    display: `${!item.access ? 'none' : 'block'}`,
                    minWidth: '180px',
                }}>
                <ListItemIcon>
                    {item.itemIcon}
                </ListItemIcon>
                {item.name}
            </MenuItem>
        </>
    );
}
