import React from "react";
import {useBranchOptions} from "../hooks";
import ContextBasedDropdownSelector from "../../common/presentation/selectors/ContextBasedDropdownSelector";
import { useSelectorValue } from "../../common/providers/selectorProvider";

export default function BranchSelector() {
    const { data: options, isLoading } = useBranchOptions()
    const defaultVal = useSelectorValue('branch') || 'All Branch';
    return (
        <ContextBasedDropdownSelector
        isLoading={isLoading}
        options={options}
        label="Branch"
        contextKey="branch"
        defaultValue={defaultVal}
        />
    )
}