import { useState, useEffect } from "react";
import SelectionList from "./SelectionList";
import AuthenticatorApp from "../AuthenticatorApp";
import ConfirmationBox from "./Confirmation";
import LoadingComponent from "../../../../common/components/LoadingComponent";

export default function MFAFormModal({ setShowModal, userDetails, isLoading}) {

    const {
        id: userId,
        user_data: {
            email: userEmail,
            username: userName,
            devices: userDevices = [],
            default_mfa_device_id: defaultDevice,
        } = {}
    } = userDetails || {};

    const hasTOTPDevice = userDevices.some(device => device.startsWith('otp_totp'));
    const isMFAEnabled = userDevices.length > 0;

    const [selectedCurrentView, setSelectedCurrentView] = useState(null);
    const [totpDevice, setTotpDevice] = useState('');

    // Set initial selectedCurrentView after userDetails is loaded
    useEffect(() => {
        if (userDetails) {
            setSelectedCurrentView(isMFAEnabled ? 'selection-list' : 'confirmation-box');
        }
    }, [userDetails, isMFAEnabled]);

    // Conditionally render the modal view based on selectedCurrentView
    const renderModalView = () => {
        if (isLoading || selectedCurrentView === null) return <LoadingComponent />;

        switch (selectedCurrentView) {
            case 'otp_totp':
                return (
                    <AuthenticatorApp
                        setSelectedCurrentView={setSelectedCurrentView}
                        userId={userId} 
                        defaultDevice={defaultDevice}
                        setShowModal={setShowModal}
                        totpDevice={totpDevice}
                        userEmail={userEmail}
                        userName={userName}
                    />
                );
            case 'selection-list':
                return (
                    <SelectionList
                        isMFAEnabled={isMFAEnabled}
                        setSelectedCurrentView={setSelectedCurrentView}
                        userId={userId} 
                        defaultDevice={defaultDevice}
                        hasTOTPDevice={hasTOTPDevice}
                        setTotpDevice={setTotpDevice}
                        setShowModal={setShowModal}
                        userDevices={userDevices}
                    />
                );
            case 'confirmation-box':
                return (
                    <ConfirmationBox
                        setShowModal={setShowModal}
                        userId={userId} 
                        setSelectedCurrentView={setSelectedCurrentView}
                        userEmail={userEmail}
                    />
                );
            default:
                return <LoadingComponent />;
        }
    };

    return <>{renderModalView()}</>;
}