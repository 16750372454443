import { Grid, Typography, Button, MenuList, MenuItem, ListItemIcon, ListItemText, styled } from "@mui/material";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import { LoginMFA_Label, LoginMFA_Title } from "./constant";
import { useState } from "react";
import ApiClient from "../../../common/API";

export default function MFAForm({ switchToOtpEmailForm, switchToAuthAppForm, defaultDevice, disabled, email, password, setCode }) {
    const [selected, setSelected] = useState(defaultDevice);
    const [otpType, setOtpType] = useState('')

    const handleMenuItemClick = (id, type) => {
        setSelected(id);
        setOtpType(type)
    };

    const handleNextClick = async (e) => {
        if (e) {
            e.preventDefault();
        }

        if (!selected) {
            alert("Please select an OTP method.");
            return;
        }

        try {
            const authResponse = await ApiClient().post('auth/', {
                username: email,
                password,
                is_long_life: false,
                selected_otp_type: otpType,
                is_for_verification: false,
            });

            const generatedCode = authResponse?.data?.code;

            if (!generatedCode) {
                alert("Authentication failed. Could not retrieve verification code.");
                return;
            }

            setCode(generatedCode);

            // Navigate to the appropriate form based on the selected method
            if (selected === "otp_email") {
                switchToOtpEmailForm();
            } else if (selected === "otp_totp") {
                switchToAuthAppForm();
            }

        } catch (error) {
            console.error("Error during verification:", error);
            alert("An error occurred while verifying the code. Please try again.");
        }
    };

    const CustomMenuItem = styled(MenuItem)(({ selected }) => ({
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        textTransform: "none",
        gap: '1.5em',
        marginBottom: '.5em',
        border: selected ? '1px solid #1976D2' : '1px solid transparent',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: 'primary',
        },
        '&.Mui-selected': {
            backgroundColor: '#EDF4FB',
        },
    }));

    const IconStyle = {
        color: "#1976D2",
        fontSize: 40
    };

    return (
        <Grid container xs={12}>
            <Grid item xs={12} sm={12} my={1} textAlign="center">
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>{LoginMFA_Title}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} my={1} textAlign="center">
                <Typography>{LoginMFA_Label}</Typography>
            </Grid>

            <Grid item xs={12} sm={12} my={1}>
                <MenuList>
                    {/* email-otp */}
                    <CustomMenuItem
                        onClick={() => handleMenuItemClick("otp_email", "email_otp")}
                        selected={selected === "otp_email"}
                    >
                        <ListItemIcon>
                            <EmailOutlinedIcon style={IconStyle} />
                        </ListItemIcon>
                        <ListItemText>Email OTP</ListItemText>
                    </CustomMenuItem>

                    {/* two-ways-totp */}
                    <CustomMenuItem
                        onClick={() => handleMenuItemClick("otp_totp", "totp")}
                        selected={selected === "otp_totp"}
                        disabled={!disabled}
                    >
                        <ListItemIcon>
                            <PhoneAndroidOutlinedIcon style={IconStyle} />
                        </ListItemIcon>
                        <ListItemText>Authenticator App</ListItemText>
                    </CustomMenuItem>
                </MenuList>
            </Grid>

            <Grid item xs={12}>
                <Button fullWidth variant="contained" sx={{ textTransform: "none" }} onClick={handleNextClick}>
                    Next
                </Button>
            </Grid>
        </Grid>
    );
}