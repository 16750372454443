export function splitDefaultDevice(defaultDevice) {
    if (!defaultDevice) return [];
    return defaultDevice.split('.')[0];
}

export const isDefaultDevice = (newDefaultDevice) => {
    if (newDefaultDevice === 'otp_totp') {
        return 'auth-app';
    } else {
        return 'otp-email';
    }
};