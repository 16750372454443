import React, { useState, useContext, useEffect } from 'react'
import { Button, Grid, TextField, Typography, Link } from "@mui/material";
import OtpCountdownTimer from "./OtpCountdownTimer";
import ApiClient from '../../common/API';
import { LoginContext } from '../../common/context/LoginContext';
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import LoadingComponent from '../../common/components/LoadingComponent';


export default function OtpForm({ otp, setOtp, switchToLoginForm, code, lockUntil, setLockUntil, switchToMFAForm, disabled}) {
    let navigate = useNavigate();
    let location = useLocation();
    let from = location.state?.from?.pathname || "/";

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [verifyTimer, setVerifyTimer] = useState(0)
    //once otp form component mounted
    //start timer to force user to relogin
    //will not wait for the code to expired
    //expiration of code: 15mins    
    useEffect(() => {
        const CodeExpiryTimer = setTimeout(() => {
            switchToLoginForm()
        }, 870000); // 14.5mins

        let toastId = null;
        const ToastNotifcationTimer = setTimeout(() => {
            toastId = toast.warn(<div>Your session is about to expire. Please complete OTP verification within <span id="countdown">60</span> seconds.</div>, {
                autoClose: false, // Don't auto-close
            });

            // Countdown timer inside the toast
            let countdown = 60;
            const countdownInterval = setInterval(() => {
                countdown--;
                if (document.getElementById("countdown")) {
                    document.getElementById("countdown").textContent = countdown;
                }

                if (countdown <= 0) {
                    clearInterval(countdownInterval);
                    toast.dismiss(toastId); // Dismiss the toast when countdown ends
                }
            }, 1000);
        }, 810000); //13.5mins

        return () => {
            clearTimeout(ToastNotifcationTimer);
            clearTimeout(CodeExpiryTimer);
        };
    }, []);

    useEffect(() => {
        let intervalId = null;
        if (verifyTimer > 0) {
            intervalId = setInterval(() => {
                setVerifyTimer(prevTimer => prevTimer - 1);
            }, 1000);
        }

        return () => clearInterval(intervalId);
    }, [verifyTimer]);

    useEffect(() => {
        setVerifyTimer(Math.floor((new Date(lockUntil) - new Date()) / 1000)) //diff in seconds
    }, [lockUntil])


    const { setLoginDetails } = useContext(LoginContext)
    const verify = (e) => {
        if (e) {
            e.preventDefault();
        }
        setIsLoading(true);
        ApiClient().post(`/auth/${code}/verify/`, { otp: otp }).then(response => {
            setIsLoading(false);
            setIsError(false);

            const canChangePassword = response.data?.can_change_password || false;
            const requirePasswordChange = response.data?.require_password_change || false;

            setLoginDetails({
                canChangePassword: canChangePassword,
                requirePasswordChange: canChangePassword && requirePasswordChange,
                username: response.data?.username,
            })
            const tokens = {
                'accessToken': response.data.access,
                'refreshToken': response.data.refresh
            }

            localStorage.setItem('auth-tokens-production', JSON.stringify(tokens));
            navigate(from, { replace: true });

        }).catch(error => {
            setLockUntil(error?.response?.data?.details?.locked_until)

            const errorMsg = error?.response?.data?.message?.[0] || 'Encountered a server error' //adding generic error 

            setIsLoading(false);
            setIsError(true);
            toast.error(errorMsg);

            if (error.response.status === 404) {
                switchToLoginForm()
            }
        });
    }

    const showLockedMessage = verifyTimer > 0;

    const buttonStyle = {
        fontWeight: "bold",
        textDecoration: disabled ? "underline" : "none",
        color: disabled ? "#1976D2" : "inherit"
    };

    if (showLockedMessage) {
        return <Grid container>
            <Grid item xs={12} sm={12} textAlign='center'>
                <Typography>Your account is temporarily locked due to multiple failed OTP verifications.</Typography>
                <Typography>Please wait for the timer to expire: </Typography>
                <Typography fontWeight='bold' variant='h6'>{`${Math.floor(verifyTimer / 60)}`.padStart(2, 0)}:{`${verifyTimer % 60}`.padStart(2, 0)}</Typography>
                <Typography>To reset your account or for urgent concerns, kindly contact  <Link href="mailto:datalabs-dataops@sminvestments.com" underline="hover">datalabs-dataops@sminvestments.com</Link></Typography>
            </Grid>
        </Grid>
    }
    return <Grid container>
        <Grid item xs={12} sm={12} textAlign='center'>
            <Typography variant='h5' fontWeight='bold' my={1}>OTP Verification</Typography>
            <Typography my={1}>Enter the OTP sent to your email</Typography>
        </Grid>
        <Grid item xs={12} sm={12} my={1} display='flex' justifyContent='center'>
            <TextField size="small" fullWidth onChange={(e) => setOtp(e.target.value)} value={otp} inputProps={{ style: { textAlign: 'center' } }} autoComplete='off'/>
        </Grid>
        <Grid item xs={12} sm={12} textAlign='center'>
            <Typography my={1}>Didn't receive OTP code?</Typography>
            <OtpCountdownTimer code={code} />
        </Grid>
        <Grid item xs={12} sm={12} my={2} display='flex' justifyContent='center'>
            {
                isLoading ? <LoadingComponent /> :
                    <Button variant="contained" sx={{ textTransform: 'none' }} fullWidth onClick={() => verify()}>Verify</Button>
            }
        </Grid>
        <Grid item xs={12} sm={12} mt={3} textAlign="center">
            <Button size="small" sx={{ textTransform: "none" }} onClick={switchToMFAForm} disabled={!disabled} >
                <Typography variant="body1"
                    sx={buttonStyle}
                >
                    Choose different method
                </Typography>
            </Button>
        </Grid>
    </Grid>
}