import { Typography, Button } from '@mui/material';
import React, {useEffect, useState, useRef} from 'react';
import { styled } from "@mui/material/styles";
import { toast } from 'react-toastify';
import ApiClient from '../../common/API';

const NewButton = styled(Button)({
    '&.Mui-disabled, &.Mui-disabled:hover': {
        pointerEvents: 'none',
        cursor: 'not-allowed'
    }
});

export default function OtpCountdownTimer({code}) {
    const seconds = 60
    const intervalId = useRef(null);
    const [time, setTime] = useState(seconds);
    const [isRestarted, setIsRestarted] = useState(false);
    const isDone = time === 0

    useEffect(() => {
        intervalId.current = setInterval(() => {
            setTime((time) => {
                if(time === 0) {    
                    clearInterval(intervalId.current)             
                    return 0;
                } else {
                    return time - 1
                }
            })
        }, 1000);
    },[isRestarted]);
    
    const resend = (e) => {
        if (e) {
            e.preventDefault();
        }

        clearInterval(intervalId.current)   
        setIsRestarted(!isRestarted)           
        setTime(seconds)
        ApiClient().post(`/auth/${code}/resend/`).then(response => {
            toast.success(response?.data?.message)
        }).catch(error => {
            console.error(error)
        });
    }
    return <>
       <Typography>Resend OTP in {`${Math.floor(time / 60)}`.padStart(2, 0)}:{`${time % 60}`.padStart(2, 0)} </Typography>
       
       <NewButton variant="text" size="small" sx={{ textTransform: 'none'}} disabled={!isDone} onClick={ ()=>resend() }>Resend OTP</NewButton>
    </>
}