import { useContext, useState } from "react";
import { Grid, TextField, Typography, Button, Box } from "@mui/material";
import QRcode from "react-qr-code"
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { useQuery } from "react-query";
import ApiClient from "../../../common/API";
import { useQueryClient } from "react-query";
import LoadingComponent from "../../../common/components/LoadingComponent";
import { toast } from "react-toastify";
import { LoginContext } from "../../../common/context/LoginContext";
import { useLocation, useNavigate, } from "react-router-dom";


export default function AuthenticatorApp({ otp, setOtp, code, switchToMFAForm, userId, setSelectedCurrentView, switchToLoginForm, totpDevice, userName }) {
    let navigate = useNavigate();
    let location = useLocation();
    let from = location.state?.from?.pathname || "/";

    const [verificationCode, setVerificationCode] = useState('');
    const [showQRCode, setShowQRCode] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const { setLoginDetails } = useContext(LoginContext)

    //Verification for login
    const verify = (e) => {
        if (e) {
            e.preventDefault();
        }
        setIsLoading(true);
        ApiClient().post(`/auth/${code}/verify/`, { otp: otp }).then(response => {
            setIsLoading(false);
            setIsError(false);

            const canChangePassword = response.data?.can_change_password || false;
            const requirePasswordChange = response.data?.require_password_change || false;

            setLoginDetails({
                canChangePassword: canChangePassword,
                requirePasswordChange: canChangePassword && requirePasswordChange,
                username: response.data?.username
            })

            const tokens = {
                'accessToken': response.data.access,
                'refreshToken': response.data.refresh
            }
            localStorage.setItem('auth-tokens-production', JSON.stringify(tokens));
            navigate(from, { replace: true });

        }).catch(error => {
            const errorMsg = "You have entered an invalid TOTP. Please try again."
            setIsLoading(false);
            setIsError(true);
            toast.error(errorMsg);

            if (error.response.status === 404) {
                switchToLoginForm()
            }
        });
    }


    const { data: config, isLoading: isLoadingConfig } = useQuery(
        ['config_url', userId],
        () => {

            return ApiClient().post(`user/${userId}/get_config_url/`).then(res => {
                return res.data
            })
        },
        {
            enabled: !!userId
        }
    );

    const qr_value = config?.config_url
    const detail = config?.detail === 'TOTP device needs to be confirmed.'
    const queryClient = useQueryClient();

    const errorToast = (error) => {
        return toast.error(error);
    }

    // Setting up of totp
    const handleSetUpTotp = async () => {
        try {
            // Validate that verificationCode is 6 digits long
            if (verificationCode.length !== 6) {
                return errorToast("Please enter a valid 6-digit code");
            }
    
            // Confirm TOTP setup
            const confirmTotpResponse = await ApiClient().post('user/setup_totp_device/', {
                device_id: totpDevice,
                code: verificationCode, 
                device_name: userName + ' - ' + totpDevice
            });
    
            if (!confirmTotpResponse?.data) {
                return errorToast("Failed to set up TOTP device.");
            }
    
            // Success case
            toast.success("Successfully verified");
            queryClient.invalidateQueries('authProfile-logged');
            setSelectedCurrentView('selection-list')
    
        } catch (error) {
            // Handle errors with fallback messaging
            const errorMessage = error.response?.data?.message?.[0] || "An error occurred during verification.";
            errorToast(errorMessage);
        }
    };

    if (isLoadingConfig ) {
        return <LoadingComponent />
    }


    if (showQRCode && qr_value && detail) {
        return (
            <Grid container fontFamily={"Henry Sans', sans-serif"}>
                <Grid item xs={12} sm={12} textAlign="center" mb={3}>
                    <Typography variant='h5' fontWeight='bold' my={1}>Setup an Authenticator App</Typography>
                </Grid>

                <Grid item xs={12} sm={12} textAlign="center" mx={3}>
                    <Typography>Scan the QR Code below using an authenticator app</Typography>
                </Grid>

                <Grid item xs={12} sm={12} my={4} container justifyContent="center" alignItems="center">
                    <QRcode value={qr_value} size={128} style={{ height: 128, width: 128 }} />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Box sx={{ backgroundColor: "#F2F2F2", borderRadius: 1, alignContent: "center", justifyContent: "center", alignItems: "center" }}>
                        <Grid item xs={12} sm={12} display="flex" m={1} gap={1}>
                            <InfoRoundedIcon fontSize="small" />
                            <Typography variant="caption">
                                Don't have an authenticator app? Download Microsoft Authenticator from the
                                <strong> App Store</strong> or <strong> Google Play</strong>.
                            </Typography>
                        </Grid>
                    </Box>
                </Grid>

                <Grid display={'flex'} xs={12} gap={1} mt={1}>
                    <Button variant="outlined" fullWidth sx={{ textTransform: "none" }} onClick={() => setSelectedCurrentView('selection-list')}>
                        Back
                    </Button>

                    <Button variant="contained" fullWidth sx={{ textTransform: "none" }} onClick={() => setShowQRCode(false)}>
                        Next
                    </Button> 
                </Grid>
            </Grid>
        );
    }

    if (switchToMFAForm) {
        return (
            <Grid container fontFamily={"Henry Sans', sans-serif"}>
                <Grid item xs={12} sm={12} textAlign="center" mb={5}>
                    <Typography variant='h5' fontWeight='bold' my={1}>Authenticator App Verification</Typography>
                </Grid>

                <Grid item xs={12} sm={12} textAlign="center" mb={3}>
                    <Typography> Enter the 6-digits code generated by your authenticator app.</Typography>
                </Grid>
                <Grid item xs={12} sm={12} textAlign="center" mb={5}>
                    <TextField 
                        id="outlined-basic" 
                        variant="outlined" 
                        size="small" 
                        fullWidth 
                        onChange={(e) => setOtp(e.target.value)} 
                        value={otp} inputProps={{ style: { textAlign: 'center' } }}
                        autoComplete="off"
                        >
                    </TextField>
                </Grid>

                <Grid item xs={12} sm={12} my={2} display='flex' justifyContent='center'>
                    {
                        isLoading ? <LoadingComponent /> :
                            <Button variant="contained" sx={{ textTransform: 'none' }} fullWidth onClick={() => verify()}>Verify</Button>
                    }
                </Grid>

                <Grid item xs={12} sm={12} mt={3} textAlign="center">
                    <Button size="small" sx={{ textTransform: "none" }} onClick={switchToMFAForm}>
                        <Typography variant="body1"
                            sx={{ fontWeight: "bold", textDecoration: "underline", color: "#1976D2" }}
                        >
                            Choose different method
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container fontFamily={"Henry Sans', sans-serif"}>
            <Grid item xs={12} sm={12} textAlign="center" mb={5}>
                <Typography variant='h5' fontWeight='bold' my={1}>Authenticator App Verification</Typography>
            </Grid>
            <Grid item xs={12} sm={12} textAlign="center" mb={3}>
                <Typography> Enter the 6-digits code generated by your authenticator app.</Typography>
            </Grid>
            <Grid item xs={12} sm={12} textAlign="center" mb={5} onKeyDown={e => e.stopPropagation()}>
                <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    autoFocus
                    fullWidth
                    inputProps={{ style: { textAlign: 'center' } }}
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    autoComplete="off"
                    >
                </TextField>
            </Grid>
            <Grid display={'flex'} xs={12} gap={1}>
                <Button xs={6} fullWidth variant="outlined" sx={{ textTransform: "none" }}
                    onClick={() => {
                        if (!qr_value) {
                            setSelectedCurrentView('selection-list');
                        } else {
                            setShowQRCode(true);
                        }
                    }}
                > Back </Button>

                <Button xs={6} fullWidth variant="contained" sx={{ textTransform: "none" }} onClick={() => handleSetUpTotp()}>
                    Verify
                </Button>
            </Grid>
        </Grid>
    )
}