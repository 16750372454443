import { Grid, Typography, Button } from "@mui/material";



export default function TwoStepVerificationForm({ switchToMFAForm }) {

    return <Grid container fontFamily={"Henry Sans', sans-serif"}>
        <Grid item xs={12} sm={12} textAlign="center">
            <Typography variant='h5' fontWeight='bold' my={1}>2-Step Verification</Typography>
        </Grid>
        <Grid item xs={12} sm={12} my={5} textAlign="center">
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>(Number Code)</Typography>
        </Grid>
        <Grid item xs={12} sm={12} textAlign="left" my={2}>
            <Typography variant="h5">Check your Phone</Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
            <Typography> SM Intelligence sent a notification to your device.
                Tap <span style={{ fontWeight: "bold" }}>Yes</span> on the notification,
                then tap <span style={{ fontWeight: "bold" }}>(Number Code)</span> on your
                phone to verify it's you.</Typography>

        </Grid>

        <Grid item xs={12} sm={12} mt={5} textAlign="center">
            <Button size="small" sx={{textTransform:"none"}} onClick={switchToMFAForm}>
                <Typography variant="body1"
                    sx={{ fontWeight: "bold", textDecoration: "underline", color: "#1976D2" }}
                >
                    Choose different method
                </Typography>
            </Button>
        </Grid>
    </Grid>
}