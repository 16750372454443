import { Grid, Typography, Button } from '@mui/material'
import ApiClient from '../../../../common/API';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';

export default function ConfirmationBox({ setShowModal, setSelectedCurrentView, userEmail}) {
    const queryClient = useQueryClient();

    const handleEnableButton = async (selectedView) => {
        try {
            await ApiClient().post('user/setup_email_device/', { 
                email: userEmail, 
                device_name: userEmail // Current default name for device
            })
            toast.success('Multi-factor authentication enabled, Default authentication method is set to email OTP')
            queryClient.invalidateQueries('authProfile-logged');
            setSelectedCurrentView(selectedView);
    
        } catch (error) {
            const errorMessage = error.response?.data?.message;
            console.error(errorMessage);  
        }
    };

    return (
        <Grid container >
            <Grid item>
                <Typography sx={{fontWeight:'bold'}}> Multi-Factor Authentication </Typography>
            </Grid>

            <Grid item sx={{mb:2}}>
                <Typography>Do you want to enable the Multi-Factor Authentication? </Typography>
            </Grid>

            <Grid item xs={12} sm={12} display={"flex"} gap={2}>
                <Grid item xs={12} sm={6}>
                    <Button fullWidth variant="outlined"  sx={{ textTransform: "none" }} onClick={() => setShowModal(false)}> Cancel </Button>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Button fullWidth variant="contained" sx={{ textTransform: "none" }} onClick={()=> handleEnableButton('selection-list')}>Enable</Button>
                </Grid>
            </Grid>
      </Grid>
    )
}